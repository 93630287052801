import React from "react";

// icons imports
import rocket from "../../../../app/assets/images/idea-booster/rocket.gif";

const unLocking = () => {
  return (
    <>
      <style jsx>
        {`
          @media (min-width: 575px) and (max-width: 1299px) {
            .card.offer-card {
              min-height: 272px !important;
              height: 100% !important;
            }
          }
          @media (max-width: 575px) {
            .idea-booster-page .card {
              margin-right: 10px !important;
            }
          }
          .card-margin .card {
            padding: 13px !important;
          }
          .card-numb,
          .popup-youtube .icon {
            background: #0c5b79;
          }
          .card-margin,
          .testi-para {
            margin-bottom: 30px;
          }
          img.img-fluid.custom-thumbnail {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        `}
      </style>
      {/* Unlocking the Path Section Start Here */}
      <div class="wg-create-design v-testi cust-padding border-line-btm">
        <div class="themesflat-container">
          <div class="row justify-content-center offer-sec pt-0">
            <div class="content text-center">
              <div class="heading-section wow fadeInUp style-2">
                <div class="sub-title text-gradient gradient-secondary">
                  Unlocking the Path
                </div>
                <div class="main-title title">
                  <h2 class="mb-0 section-head">
                    What Idea Booster{" "}
                    <img src={rocket} class="rocket-gif" alt="ib-img" /> Offers
                  </h2>
                </div>
              </div>
              <p class="text-dark vdo-sec-txt cust-head-para">
                Idea Booster provides a complete array of services to elevate
                your app concept from initial ideation to a proven and
                compelling idea that attracts the necessary resources for
                development and launch.
              </p>
            </div>

            <div class="col-lg-4 col-md-6 card-margin">
              <div class="card offer-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">1</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Idea Refinement</h4>
                  </div>
                  <p class="text-dark">
                    {" "}
                    We refine your app idea to meet market needs and appeal to
                    your target audience, establishing a solid development
                    foundation.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 card-margin">
              <div class="card offer-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">2</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Technical Feasibility</h4>
                  </div>
                  <p class="text-dark">
                    Our experts evaluate the technical feasibility of your app,
                    ensuring a smooth development process without feature
                    compromises.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 card-margin">
              <div class="card offer-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">3</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">App Design</h4>
                  </div>
                  <p class="text-dark">
                    We create UX/UI designs with wireframes, mockups, and
                    prototypes to optimize user experience and gather feedback
                    before development.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 card-margin">
              <div class="card offer-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">4</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Development Cost Analysis</h4>
                  </div>
                  <p class="text-dark">
                    {" "}
                    We offer a detailed financial analysis for development,
                    maintenance, and updates, aiding in budgeting and funding
                    efforts.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 card-margin">
              <div class="card offer-card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div>
                      <h3 class="card-numb">5</h3>
                    </div>
                    <h4 class="mb-3 ms-3 fw-bold">Pitch Deck Service</h4>
                  </div>
                  <p class="text-dark">
                    Our pitch deck showcases your app's market potential, value,
                    and team to attract investors and partners effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default unLocking;
