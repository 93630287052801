import React from "react";

// icons imports
import bullseye from "../../../../app/assets/images/idea-booster/bullseye.png";
import arrowdown from "../../../../app/assets/images/idea-booster/arrow-down.gif";
import cutout1 from "../../../../app/assets/images/idea-booster/vision.jpg";
// bg gradient balls import
import ball1 from "../../../../app/assets/images/idea-booster/vision-ball.png";
import ball2 from "../../../../app/assets/images/idea-booster/vision-ball2.png";

const Inovative = () => {
  return (
    <>
      <style jsx>
        {`
          .wg-create-design {
            padding: 100px 0 70px;
            background: no-repeat #f5f5f5;
          }
          .section-head {
            font-size: 60px;
          }
          .box-feature-item p,
          .wg-create-design .content p {
            margin-bottom: 24px;
          }
          .wg-create-design .heading-section {
            margin-bottom: 18px;
          }
          .offer-card h4,
          .vdo-sec-txt {
            font-size: 24px !important;
          }
          .v-testi .card-body {
            padding: 12px !important;
          }
          .testimonial-video {
            width: 100% !important;
            height: 100% !important;
          }
          .testimonial-video video {
            border-radius: 10px;
            width: 100%;
          }
          .offer-sec,
          .pt-100 {
            padding-top: 100px;
          }
          .cust-head-para {
            padding-left: 220px;
            padding-right: 220px;
            margin-bottom: 50px !important;
          }
          .vdo-sec-txt {
            line-height: 1.4;
          }
          .idea-booster-page .card {
            border-radius: 15px;
            border: none;
            box-shadow: rgba(149, 157, 165, 0.1) 0 8px 24px;
            box-shadow: none;
          }
          .card-numb {
            color: #fff;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 50px;
            margin-bottom: 15px;
            flex-shrink: 0;
          }
        `}
      </style>

      {/* Innovative Realization Section Start Here */}
      <div class="wg-result cust-padding-2 bg-black overflow-hidden border-line-btm">
        <div class="themesflat-container">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="heading-section content text-center wow fadeInUp">
                <div class="sub-title text-gradient">
                  Innovative Realization
                </div>
                <div class="main-title title">
                  <h2 class="mb-0">
                    Transform Your <br />
                    Vision{" "}
                    <img
                      src={bullseye}
                      class="ms-0 rocket-gif"
                      alt="ib-img"
                    />{" "}
                    into Reality
                  </h2>
                </div>
              </div>
            </div>

            <div class="col-lg-7">
              <p class="mb-4">
                Those with experience in app development advise starting small.
                This means validating your idea before heavily investing in the
                full development process.{" "}
                <b>
                  CAP Digisoft's Idea Booster program enables cost-effective,
                  insightful validation by designing your app screen by screen
                  for user feedback without a large initial cost.
                </b>{" "}
                This approach can draw co-founders, investors, and users,
                facilitating a smoother market entry.
              </p>

              <h3 class="head-gradient my-4">
                This isn't just about aesthetics;
                <br /> It's about experience.
              </h3>

              <p
                className="
                mb-5"
              >
                Leveraging extensive experience in successful mobile app design,
                CAP Digisoft collaborates closely to{" "}
                <b>ensure your product is usable, marketable & scalable </b>{" "}
                incorporating cost efficiency, technology consideration, and
                optimal user experience into our design philosophy.
              </p>
            </div>

            <div class="col-lg-5 mt-lg-0 mt-md-5">
              <div class="text-lg-end text-center position-relative d-none d-sm-block">
                <img src={cutout1} class="img-fluid vision-img" alt="ib-img" />

                <div class="vision-bg">
                  <img src={ball1} class="vison-1" alt="ib-img" />
                  <img src={ball2} class="vison-2" alt="ib-img" />
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="text-center mx-auto mt-3">
                <div class="d-flex flex-column align-items-center">
                  <div class="free-con">
                    <img src={arrowdown} alt="ib-img" />
                  </div>

                  <a
                    href="#"
                    class="tf-button get-start h45 mx-auto btn-glow"
                    data-bs-toggle="modal"
                    data-bs-target="#meeting"
                  >
                    <span>Let's Begin Your Journey Together</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Inovative;
