import React from "react";

// marquee images imports
import mc1 from "../../../../app/assets/images/idea-booster/marquee-media/m1.png";
import mc2 from "../../../../app/assets/images/idea-booster/marquee-media/m2.png";
import mc3 from "../../../../app/assets/images/idea-booster/marquee-media/m3.png";
import mc4 from "../../../../app/assets/images/idea-booster/marquee-media/m4.png";
import mc5 from "../../../../app/assets/images/idea-booster/marquee-media/m5.png";
import mc6 from "../../../../app/assets/images/idea-booster/marquee-media/m6.png";
import mc7 from "../../../../app/assets/images/idea-booster/marquee-media/m7.png";
import mc8 from "../../../../app/assets/images/idea-booster/marquee-media/m8.png";
import mc9 from "../../../../app/assets/images/idea-booster/marquee-media/m9.png";
import mc10 from "../../../../app/assets/images/idea-booster/marquee-media/m10.png";
import mc11 from "../../../../app/assets/images/idea-booster/marquee-media/m11.png";

const Hero = () => {
  return (
    <>
      <style jsx>
        {`
          .language-item {
            display: block;
            width: 300px;
            text-align: center;
            background: #e6eff3;
            border-radius: 16px;
            padding: 30px;
          }
          .language-item .title a:hover {
            color: #030014;
          }
          .language-item img {
            margin-bottom: 20px;
            border-radius: 0;
          }
          .fura-logo-text,
          .jugl-logo-text,
          .logo-text {
            width: 100px;
          }
          .language-item .title {
            font-size: 22px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: -0.66px;
          }
          .language-item .title a {
            color: #030014;
            font-weight: 600;
            text-decoration: none;
          }
          .marquee {
            overflow: hidden;
            user-select: none;
          }
          .marquee,
          .marquee__group {
            display: flex;
            gap: var(--gap);
          }
          .marquee__group {
            flex-shrink: 0;
            align-items: center;
            justify-content: space-around;
            min-width: 90%;
            animation: scroll-x var(--duration) linear infinite;
          }
          @media (prefers-reduced-motion: reduce) {
            .marquee__group {
              animation-play-state: paused;
            }
          }
          .marquee--vertical {
            --mask-direction: to bottom;
          }
          .marquee--vertical .marquee__group {
            animation-name: scroll-y;
          }
          .marquee--reverse .marquee__group {
            animation-direction: reverse;
            animation-delay: -3s;
          }
          @keyframes scroll-x {
            from {
              transform: translateX(var(--scroll-start));
            }
            to {
              transform: translateX(var(--scroll-end));
            }
          }
          @keyframes scroll-y {
            from {
              transform: translateY(var(--scroll-start));
            }
            to {
              transform: translateY(var(--scroll-end));
            }
          }
          .marquee svg {
            width: var(--size);
            aspect-ratio: 16/9;
            padding: calc(var(--size) / 10);
            border-radius: 0.5rem;
          }
          .marquee--vertical .product-wrapper,
          .marquee--vertical svg {
            aspect-ratio: 1;
            width: calc(var(--size) / 1.5);
            padding: calc(var(--size) / 6);
          }
          .marquee .product-wrapper {
            width: var(--size);
            aspect-ratio: 16/9;
            padding: calc(var(--size) / 10);
            border-radius: 0.8rem;
          }
          .language-item img {
            width: 85px;
          }
          @media (max-width: 575px) {
            .language-item {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              width: 170px;
              height: 190px;
              text-align: center;
              border-radius: 16px;
              padding: 30px;
            }
          }
        `}
      </style>

      {/* Apps We've Worked with Section Start Here */}
      <div class="wg-result cust-padding bg-black border-line-btm">
        <div class="content text-center">
          <div class="heading-section wow fadeInUp style-2">
            <div class="main-title title">
              <h2 class="mb-0 section-head">Apps We've Worked with</h2>
            </div>
          </div>
        </div>

        <article class="wrapper">
          <div class="marquee mb-4">
            <div class="marquee__group">
              <div class="language-item">
                <img src={mc1} alt="ib-img" />
                <div class="title">
                  <a href="#">Fura</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc2} alt="ib-img" />
                <div class="title">
                  <a href="#">Jugl</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc3} alt="ib-img" />
                <div class="title">
                  <a href="#">Attesta</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc4} alt="ib-img" />
                <div class="title">
                  <a href="#">Clued.in Closet</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc5} alt="ib-img" />
                <div class="title">
                  <a href="#">iLedgers</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc6} alt="ib-img" />
                <div class="title">
                  <a href="#">aiya</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc7} alt="ib-img" />
                <div class="title">
                  <a href="#">Carfidante</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc8} alt="ib-img" />
                <div class="title">
                  <a href="#">Hire Harmony</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc9} alt="ib-img" />
                <div class="title">
                  <a href="#">Intellicue</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc10} alt="ib-img" />
                <div class="title">
                  <a href="#">J&amp;P</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc11} alt="ib-img" />
                <div class="title">
                  <a href="#">Bussopi</a>
                </div>
              </div>
            </div>

            <div aria-hidden="true" class="marquee__group">
              <div class="language-item">
                <img src={mc1} alt="ib-img" />
                <div class="title">
                  <a href="#">Fura</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc2} alt="ib-img" />
                <div class="title">
                  <a href="#">Jugl</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc3} alt="ib-img" />
                <div class="title">
                  <a href="#">Attesta</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc4} alt="ib-img" />
                <div class="title">
                  <a href="#">Clued.in Closet</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc5} alt="ib-img" />
                <div class="title">
                  <a href="#">iLedgers</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc6} alt="ib-img" />
                <div class="title">
                  <a href="#">aiya</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc7} alt="ib-img" />
                <div class="title">
                  <a href="#">Carfidante</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc8} alt="ib-img" />
                <div class="title">
                  <a href="#">Hire Harmony</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc9} alt="ib-img" />
                <div class="title">
                  <a href="#">Intellicue</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc10} alt="ib-img" />
                <div class="title">
                  <a href="#">J&amp;P</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc11} alt="ib-img" />
                <div class="title">
                  <a href="#">Bussopi</a>
                </div>
              </div>
            </div>
          </div>

          <div class="marquee marquee--reverse">
            <div class="marquee__group">
              <div class="language-item">
                <img src={mc1} alt="ib-img" />
                <div class="title">
                  <a href="#">Fura</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc2} alt="ib-img" />
                <div class="title">
                  <a href="#">Jugl</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc3} alt="ib-img" />
                <div class="title">
                  <a href="#">Attesta</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc4} alt="ib-img" />
                <div class="title">
                  <a href="#">Clued.in Closet</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc5} alt="ib-img" />
                <div class="title">
                  <a href="#">iLedgers</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc6} alt="ib-img" />
                <div class="title">
                  <a href="#">aiya</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc7} alt="ib-img" />
                <div class="title">
                  <a href="#">Carfidante</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc8} alt="ib-img" />
                <div class="title">
                  <a href="#">Hire Harmony</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc9} alt="ib-img" />
                <div class="title">
                  <a href="#">Intellicue</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc10} alt="ib-img" />
                <div class="title">
                  <a href="#">J&amp;P</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc11} alt="ib-img" />
                <div class="title">
                  <a href="#">Bussopi</a>
                </div>
              </div>
            </div>

            <div aria-hidden="true" class="marquee__group">
              <div class="language-item">
                <img src={mc1} alt="ib-img" />
                <div class="title">
                  <a href="#">Fura</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc2} alt="ib-img" />
                <div class="title">
                  <a href="#">Jugl</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc3} alt="ib-img" />
                <div class="title">
                  <a href="#">Attesta</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc4} alt="ib-img" />
                <div class="title">
                  <a href="#">Clued.in Closet</a>
                </div>
              </div>
              <div class="language-item">
                <img src={mc5} alt="ib-img" />
                <div class="title">
                  <a href="#">iLedgers</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc6} alt="ib-img" />
                <div class="title">
                  <a href="#">aiya</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc7} alt="ib-img" />
                <div class="title">
                  <a href="#">Carfidante</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc8} alt="ib-img" />
                <div class="title">
                  <a href="#">Hire Harmony</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc9} alt="ib-img" />
                <div class="title">
                  <a href="#">Intellicue</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc10} alt="ib-img" />
                <div class="title">
                  <a href="#">J&amp;P</a>
                </div>
              </div>

              <div class="language-item">
                <img src={mc11} alt="ib-img" />
                <div class="title">
                  <a href="#">Bussopi</a>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Hero;
