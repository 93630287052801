import React from "react";

import arrowdown from "../../../../app/assets/images/idea-booster/arrow-down.gif";
import phone from "../../../../app/assets/images/idea-booster/phone.png";

const Hero = () => {
  return (
    <>
      <style jsx>{``}</style>
      {/* Confidently realize app ideas Section Start Here */}
      <div class="wg-result cust-padding-2 bg-black border-line-btm">
        <div class="themesflat-container">
          <div class="row justify-content-center">
            <div class="content text-center">
              <div class="heading-section wow fadeInUp style-2">
                <div class="sub-title text-gradient mb-3">
                  Confidently realize app ideas
                </div>
                <div class="main-title title">
                  <h2 class="mb-0 section-head">
                    Bringing Your App{" "}
                    <img src={phone} class="rocket-gif" alt="ib-img ms-0" />{" "}
                    Ideas to Life with Confidence
                  </h2>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="cust-width">
                <p class="mb-5 text-center">
                  Addressing the daunting statistic that only 0.5% of apps
                  succeed, CAP Digisoft’s Idea Booster and Idea to Product, are
                  designed to navigate through the common pitfalls of app
                  development such as lack of product-market fit, inadequate
                  marketing strategy, and weak business infrastructure. Idea
                  Booster fine-tunes your app idea with targeted user feedback,
                  ensuring it meets market demands and stands out, while Idea to
                  Product transforms these validated concepts into market-ready
                  apps, integrating development, marketing, and strategic
                  business planning.
                </p>
                <p class="mb-0 text-center">
                  Our programs are bolstered by CAP Digisoft's ISO 27001 and
                  HIPAA certifications, emphasizing our commitment to quality
                  and data security in bringing your app to life. Through a
                  combination of expert guidance, strategic development, and
                  comprehensive market analysis, we aim to elevate your app into
                  the successful 0.5%, ensuring it is not only developed with
                  precision but also positioned for market success and growth.
                </p>
              </div>

              <div class="text-center mx-auto mt-3">
                <div class="d-flex flex-column align-items-center">
                  <div class="free-con">
                    <img src={arrowdown} alt="ib-img" />
                  </div>

                  <a
                    href="#"
                    class="tf-button get-start h45 mx-auto btn-glow"
                    data-bs-toggle="modal"
                    data-bs-target="#meeting"
                  >
                    <span>
                      Start Building with Clarity and Affordability – Click to
                      Begin
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Hero;
