import React from "react";

import arrowdown from "../../../../app/assets/images/idea-booster/arrow-down.gif";
import hand from "../../../../app/assets/images/idea-booster/hand.png";

const Hero = () => {
  return (
    <>
      <style jsx>{``}</style>
      {/* Dream into an App Section Start Here */}
      <div class="wg-result cust-padding-2 bg-black border-line-btm">
        <div class="themesflat-container">
          <div class="row justify-content-center">
            <div class="content text-center">
              <div class="heading-section wow fadeInUp style-2">
                <div class="sub-title text-gradient">
                  Turn Your Dream into an App
                </div>
                <div class="main-title title">
                  <h2 class="mb-0 section-head">
                    Ready{" "}
                    <img src={hand} class="rocket-gif ms-0" alt="ib-img " />
                    to Start?
                  </h2>
                </div>
              </div>
            </div>

            <div class="gallery-sec-para ">
              <p class="text-center mb-5">
                Taking the first step towards realizing your app development
                dream is easier
                <br class="d-md-block d-none" />
                than you think. With CAP Digisoft's Idea Booster program, you're
                gaining a
                <br class="d-md-block d-none" />
                partner dedicated to transforming your idea into reality.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center mx-auto expertise-cta mt-0">
              <p>
                Like only 8% of the found stake action on their idea,{" "}
                <br class="d-md-block d-none" />
                be part of that 8% and schedule a FREE consulting today
              </p>

              <div class="d-flex flex-column align-items-center mt-3">
                <div class="free-con">
                  <img src={arrowdown} alt="ib-img" />
                </div>

                <a
                  href="#"
                  class="tf-button get-start h45 mx-auto btn-glow"
                  data-bs-toggle="modal"
                  data-bs-target="#meeting"
                >
                  <span>Schedule a no-obligation consultation today</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Hero;
