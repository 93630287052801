import React from "react";

// cutout imports
import cutout3 from "../../../../app/assets/images/idea-booster/cutout3.jpg";
import cutout4 from "../../../../app/assets/images/idea-booster/cutout4.jpg";
// bg gradient balls import
import ball1 from "../../../../app/assets/images/idea-booster/vision-ball.png";
import ball2 from "../../../../app/assets/images/idea-booster/vision-ball2.png";

const leverage = () => {
  return (
    <>
      <style jsx>
        {`
          .grad-ball .grad-ball-2 {
            position: absolute;
            bottom: -170px;
            left: -120px;
          }
          .grad-ball .grad-ball-1 {
            position: absolute;
            top: -200px;
            right: -130px;
          }
          .sub-sec-img {
            position: relative;
            z-index: 1;
          }
          .sub-section h3 {
            font-size: 60px;
            line-height: 1.2;
            font-weight: 500;
          }
        `}
      </style>

      {/* Leverage Our Expertise Section Start Here */}
      <div class="wg-result cust-padding bg-black overflow-hidden border-line-btm">
        <div class="themesflat-container">
          <div class="row justify-content-center align-items-center">
            <div class="col-xxl-7 col-md-7 order-lg-1 order-2">
              <div class="sub-section">
                <h3 class="text-white mb-4">
                  Leverage Our Expertise and Credibility
                </h3>
                <p>
                  As your technical partner, CAP Digisoft's reputation becomes a
                  powerful tool in attracting further investment. Our
                  credibility in the tech industry acts as a testament to the
                  potential of your app, providing investors with the confidence
                  they need to commit to your project.
                </p>
              </div>
            </div>

            <div class="col-xxl-5 col-md-5 order-lg-2 order-1">
              <div class="text-center position-relative">
                <img
                  src={cutout3}
                  class="sub-sec-img img-fluid mb-4"
                  alt="ib-img"
                />

                <div class="grad-ball d-lg-block d-none">
                  <img src={ball1} class="grad-ball-1 img-fluid" alt="ib-img" />
                  <img src={ball2} class="grad-ball-2 img-fluid" alt="ib-img" />
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center align-items-center mt-5">
            <div class="col-xxl-5 col-md-5">
              <div class="text-center position-relative">
                <img
                  src={cutout4}
                  class="sub-sec-img img-fluid mb-4"
                  alt="ib-img"
                />
                <div class="grad-ball d-lg-block d-none">
                  <img src={ball1} class="grad-ball-1" alt="ib-img" />
                  <img src={ball2} class="grad-ball-2" alt="ib-img" />
                </div>
              </div>
            </div>

            <div class="col-xxl-7 col-md-7">
              <div class="sub-section">
                <h3 class="text-white mb-4">
                  From Start to Launch: A Partnership for Success
                </h3>
                <p>
                  Our commitment goes beyond just getting started; we are with
                  you every step of the way, from the spark of an idea to the
                  excitement of the launch and beyond. CAP Digisoft isn't just
                  about building apps; we're about building successes. Join us
                  on this journey to turn your app into the next market
                  sensation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default leverage;
