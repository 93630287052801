import React from "react";

// cutout imports
import cutout2 from "../../../../app/assets/images/idea-booster/cutout2.jpg";
// bg gradient balls import
import ball1 from "../../../../app/assets/images/idea-booster/vision-ball.png";
import ball2 from "../../../../app/assets/images/idea-booster/vision-ball2.png";
// sr icons imports
import sr1 from "../../../../app/assets/images/idea-booster/sr1.png";
import sr2 from "../../../../app/assets/images/idea-booster/sr2.png";
import sr3 from "../../../../app/assets/images/idea-booster/sr3.png";
import sr4 from "../../../../app/assets/images/idea-booster/sr4.png";

const Hero = () => {
  return (
    <>
      <style jsx>{``}</style>
      {/* App Journey with Idea Booster! Section Start Here */}
      <div class="wg-result cust-padding  bg-black overflow-hidden border-line-btm">
        <div class="themesflat-container">
          <div class="row justify-content-center align-items-center">
            <div class="col-12">
              <div class="content text-center">
                <div class="heading-section wow fadeInUp style-2">
                  <div class="sub-title text-gradient">
                    App Journey with Idea Booster!
                  </div>
                  <div class="main-title title">
                    <h2 class="mb-0 section-head px-3">
                      Empower Your App's Journey: From Concept to Launch with
                      Idea Booster
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div>
                <p class="mb-5">
                  Unlock the full potential of your app idea with Idea Booster.
                  Prove market demand, attract vital investments, and leverage
                  CAP Digisoft's comprehensive suite of services for complete
                  app development, strategic market entry, launch support, and
                  beyond. With us, gain a technical partner whose expertise and
                  credibility opens doors to investors, ensuring a seamless path
                  from inception to market success.
                </p>

                <h5 class="text-gradient">
                  Comprehensive Support with Idea Booster
                </h5>
                <p class="mb-5">
                  At the heart of every successful app is an idea validated by
                  market demand and backed by strategic investment. Idea Booster
                  by CAP Digisoft is your first step towards turning your vision
                  into a viable, market-ready product. Our initial phase focuses
                  on validating your app's market demand, a critical step in
                  attracting the investment needed to bring your idea to life.
                </p>

                <h5 class="text-gradient">
                  Beyond Validation: Your Journey to Launch
                </h5>
                <p>
                  Validation is just the beginning. With CAP Digisoft, you
                  transition seamlessly from concept validation to full-scale
                  development. Our end-to-end services cover.
                </p>
              </div>
            </div>

            <div class="col-lg-6 position-relative">
              <div class="text-center">
                <img src={cutout2} class="mob-img img-fluid" alt="ib-img" />
              </div>

              <div class="blur-ball">
                <img src={ball1} class="ball-1" alt="ib-img" />
                <img src={ball2} class="ball-2" alt="ib-img" />
              </div>
            </div>

            <div class="col-12 cust-spacing">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-5">
                    <img src={sr1} alt="ib-img" />
                    <h5 class="text-white my-3 fw-bold">
                      Complete App Development
                    </h5>
                    <p>
                      Leveraging the latest technologies and design principles,
                      we transform your validated idea into a fully functional
                      app.
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-5">
                    <img src={sr2} alt="ib-img" />
                    <h5 class="text-white my-3 fw-bold">
                      Go-To-Market Strategy
                    </h5>
                    <p>
                      Navigate the complexities of market entry with a
                      tailor-made strategy, ensuring your app reaches its target
                      audience effectively.
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-5">
                    <img src={sr3} alt="ib-img" />
                    <h5 class="text-white my-3 fw-bold">Launch Support</h5>
                    <p>
                      Benefit from our expertise in launching apps successfully,
                      making your debut in the market smooth and impactful.
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="">
                    <img src={sr4} alt="ib-img" />
                    <h5 class="text-white my-3 fw-bold">
                      Ongoing Customer Support
                    </h5>
                    <p>
                      We provide continuous support to ensure your app remains
                      competitive, addressing user feedback and implementing
                      necessary updates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Hero;
