import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import arrowdown from "../../../../app/assets/images/idea-booster/arrow-down.gif";
// video imports
import testmonial from "../../../../app/assets/images/idea-booster/testimonial.mp4";
import testmonial2 from "../../../../app/assets/images/idea-booster/testimonial2.mp4";
import testmonial4 from "../../../../app/assets/images/idea-booster/testimonial4.mp4";

// video thumbnail images imports
import thumb1 from "../../../../app/assets/images/idea-booster/thumb1.jpg";
import thumb2 from "../../../../app/assets/images/idea-booster/thumb2.jpg";
import thumb4 from "../../../../app/assets/images/idea-booster/thumb4.jpg";

// client testimonials images imports
import test1 from "../../../../app/assets/images/idea-booster/client1.png";
import test2 from "../../../../app/assets/images/idea-booster/client2.png";
import test3 from "../../../../app/assets/images/idea-booster/client3.png";

const testimonial = () => {
  const properties2 = {
    prevArrow: (
      <button className="cs-btn2 ib-btn-left">
        <svg
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 5H3.5C3.22 5 3 4.78 3 4.5C3 4.22 3.22 4 3.5 4H12.5C12.78 4 13 4.22 13 4.5C13 4.78 12.78 5 12.5 5Z"
            fill="black"
          />
          <path
            d="M6 8.5C5.93442 8.5008 5.86941 8.48787 5.80913 8.46203C5.74885 8.4362 5.69465 8.39804 5.65 8.35L2.15 4.85C1.95 4.65 1.95 4.34 2.15 4.14L5.65 0.65C5.85 0.45 6.16 0.45 6.36 0.65C6.56 0.85 6.56 1.16 6.36 1.36L3.21 4.51L6.36 7.66C6.56 7.86 6.56 8.17 6.36 8.37C6.26 8.47 6.13 8.52 6.01 8.52L6 8.5Z"
            fill="black"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="cs-btn2 btn-right ib-btn-right">
        <svg
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 5H3.5C3.22 5 3 4.78 3 4.5C3 4.22 3.22 4 3.5 4H12.5C12.78 4 13 4.22 13 4.5C13 4.78 12.78 5 12.5 5Z"
            fill="black"
          />
          <path
            d="M10 8.5C9.93442 8.5008 9.86941 8.48787 9.80913 8.46203C9.74885 8.4362 9.69465 8.39804 9.65 8.35C9.45 8.15 9.45 7.84 9.65 7.64L12.8 4.49L9.65 1.34C9.45 1.14 9.45 0.83 9.65 0.63C9.85 0.43 10.16 0.43 10.36 0.63L13.86 4.13C14.06 4.33 14.06 4.64 13.86 4.84L10.36 8.34C10.26 8.44 10.13 8.49 10.01 8.49L10 8.5Z"
            fill="black"
          />
        </svg>
      </button>
    ),
  };
  return (
    <>
      <style jsx>{`
        div#video-container {
          width: 50%;
          position: relative;
        }
        .player-button {
          background: radial-gradient(#f5f5f5bd, #fff 60%) !important;
          color: #214352;
        }
        .react-player__play-icon {
          border-color: transparent transparent transparent #14303c !important;
        }
        button.cs-btn2.ib-btn-left.nav {
          left: unset;
          right: 60px;
        }
        button.cs-btn2.ib-btn-left.nav,
        button.cs-btn2.ib-btn-right.nav {
          margin-right: 0;
          bottom: 140px;
          top: -90px;
          padding: 10px 8px;
          height: 50px;
          width: 50px;
          justify-content: center;
          align-content: center;
        }
        .testi-para {
          text-align: start;
          margin-bottom: 16px;
        }
        .testi-image {
          margin-right: 12px;
        }
        .testimonial-content {
          margin-right: 16px;
          width: 50%;
        }
        @media (max-width: 992px) {
          .react-slideshow-wrapper .images-wrap.horizontal .testimonial-slider {
            align-items: baseline;
          }
          .testimonial-content {
            margin-bottom: 20px;
          }
        }
        @media (max-width: 475px) {
          button.cs-btn2.ib-btn-left.nav,
          button.cs-btn2.ib-btn-right.nav {
            margin-right: 0;
            top: unset;
            bottom: -20px;
          }
          button.cs-btn2.ib-btn-left.nav {
            left: 49%;
            transform: translateX(-100%);
          }
          button.cs-btn2.ib-btn-right.nav {
            right: 48%;
            transform: translateX(100%);
          }
        }
        @media (max-width: 800px) {
          .testimonial-wrapper {
            display: block;
          }
          .testimonial-content,
          div#video-container {
            width: 100%;
          }
        }
        @media (max-width: 575px) {
          .idea-booster-page .card {
            margin-bottom: 50px;
            margin-right: 10px;
          }
        }
      `}</style>
      {/* Testimonials Section Start Here */}
      <div class="wg-result cust-padding-2 bg-black border-line-btm">
        <div class="themesflat-container">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="content">
                <div class="heading-section wow fadeInUp style-2">
                  <div class="sub-title text-gradient">
                    Reliability, Expertise, & Exceptional Results
                  </div>
                  <div class="main-title title text-start">
                    <h2 class="mb-0 section-head p-0">Testimonials</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="banner-content position-relative">
            <Slide {...properties2} autoplay={false} duration={3000}>
              <div className="each-slide-effect">
                <div class="card text-center testi-card-custom h-100 ">
                  <div class="card-body">
                    <div class="testimonial-wrapper">
                      <div class="testimonial-content">
                        <p class="testi-para">
                          “CAP Digisoft has been exceptional in bringing my AI
                          software idea to life through their top-notch
                          creativity and extensive experience. From initial
                          discussions to development, their valuable guidance
                          made the process seamless. Their expertise ensured
                          ease in navigating steps, enhancing the idea, and
                          making it a reality. I highly recommend CAP Digisoft
                          for any future tech or digital projects.”
                        </p>

                        <div class="rating-wrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </div>

                        <div class="d-flex align-items-center">
                          <div class="testi-image">
                            <img src={test1} alt="ib-img" />
                          </div>

                          <div class="">
                            <h6 class="text-white">Omar Mora</h6>
                            <p class="m-0 job-role">BUSSOPI</p>
                          </div>
                        </div>
                      </div>
                      <div class="video-container" id="video-container">
                        <button
                          type="button"
                          class="btn btn-primary rounded-circle player-button"
                          data-bs-toggle="modal"
                          data-bs-target="#FeedbackOne"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            fill="currentColor"
                            class="bi bi-play-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                          </svg>
                        </button>
                        <img
                          className="img-fluid custom-thumbnail"
                          src={thumb2}
                          alt="Thumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="each-slide-effect">
                <div class="card text-center testi-card-custom">
                  <div class="card-body">
                    <div class="testimonial-wrapper">
                      <div class="testimonial-content">
                        <p class="testi-para">
                          INZYNCH, as a disruptive company in team and
                          organization management, needed to migrate from
                          proprietary Excel tools to a scalable platform. After
                          evaluating eight options, CAP Digisoft stood out with
                          their quick and high-quality response, including a
                          demo that exceeded expectations. This proactive
                          approach not only aligned with our needs but also
                          helped generate excitement among our current clients.
                          We look forward to a long-term partnership with CAP
                          Digisoft.”
                        </p>
                        <div class="rating-wrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </div>

                        <div class="d-flex align-items-center">
                          <div class="testi-image">
                            <img src={test2} alt="ib-img" />
                          </div>

                          <div class="">
                            <h6 class="text-white">Alberto Garcia</h6>
                            <p class="m-0 job-role">INZYNCH</p>
                          </div>
                        </div>
                      </div>
                      <div class="video-container" id="video-container">
                        <button
                          type="button"
                          class="btn btn-primary rounded-circle player-button"
                          data-bs-toggle="modal"
                          data-bs-target="#FeedbackTwo"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            fill="currentColor"
                            class="bi bi-play-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                          </svg>
                        </button>
                        <img
                          className="img-fluid custom-thumbnail"
                          src={thumb1}
                          alt="Thumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="each-slide-effect">
                <div class="card text-center testi-card-custom h-100 ">
                  <div class="card-body">
                    <div class="testimonial-wrapper">
                      <div class="testimonial-content">
                        <p class="testi-para">
                          "Working with offshore teams led by senior executives
                          like Arun has been a common experience for me over the
                          last 25 years. What sets this team apart is their
                          balance of guidance and freedom, resulting in
                          impressive creativity and quality work. I'll keep CAP
                          Digisoft in my contacts for future projects and
                          referrals, and I look forward to celebrating our
                          successes together someday."
                        </p>
                        <div class="rating-wrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-star-fill text-yellow"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </div>

                        <div class="d-flex align-items-center">
                          <div class="testi-image">
                            <img src={test3} alt="ib-img" />
                          </div>

                          <div class="">
                            <h6 class="text-white">Kelvin Yu</h6>
                            <p class="m-0 job-role">BUSSOPI</p>
                          </div>
                        </div>
                      </div>
                      <div class="video-container" id="video-container">
                        <button
                          type="button"
                          class="btn btn-primary rounded-circle player-button"
                          data-bs-toggle="modal"
                          data-bs-target="#FeedbackThree"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            fill="currentColor"
                            class="bi bi-play-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                          </svg>
                        </button>
                        <img
                          className="img-fluid custom-thumbnail"
                          src={thumb4}
                          alt="Thumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            {/* testimonials video modals */}
            <div className="testimonials-video_bucket">
              <div
                class="modal fade"
                id="FeedbackOne"
                tabindex=""
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <video
                        className="testimonial-video rounded"
                        loop="true"
                        controls="true"
                        autoplay="true"
                        muted="false"
                      >
                        <source src={testmonial2} type="video/mp4" />
                      </video>
                    </div>
                    {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button> */}
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id="FeedbackTwo"
                tabindex=""
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <video
                        className="testimonial-video rounded"
                        loop="true"
                        controls="true"
                        autoplay="true"
                        muted="false"
                      >
                        <source src={testmonial} type="video/mp4" />
                      </video>
                    </div>
                    {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button> */}
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id="FeedbackThree"
                tabindex=""
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <video
                        className="testimonial-video rounded"
                        loop="true"
                        controls="true"
                        autoplay="true"
                        muted="false"
                      >
                        <source src={testmonial4} type="video/mp4" />
                      </video>
                    </div>
                    {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mx-auto mt-3">
            <div class="d-flex flex-column align-items-center">
              <div class="free-con">
                <img src={arrowdown} alt="ib-img" />
              </div>

              <a
                href="#"
                class="tf-button get-start h45 mx-auto btn-glow"
                data-bs-toggle="modal"
                data-bs-target="#meeting"
              >
                <span>Click Here to Kickstart Your Dream App!</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default testimonial;
