import React from "react";
import LazyLoad from "react-lazyload";

// icons imports
import star from "../../../../app/assets/images/idea-booster/star.png";
import rocket from "../../../../app/assets/images/idea-booster/rocket.gif";
import arrow from "../../../../app/assets/images/idea-booster/arrow-hero.png";

const Hero = () => {
  return (
    <>
      <style jsx>
        {`
          header {
            position: relative;
            z-index: 99;
          }

          .star-icon .star-1 {
            position: absolute;
            bottom: -60px;
            left: 200px;
          }
          .star-icon .star-2 {
            position: absolute;
            top: -110px;
            right: 109px;
          }
          .wg-banner-1 {
            padding-top: 150px;
            padding-bottom: 160px;
            background: url(../../../app/assets/images/idea-booster/bannger-bg.jpg)
              bottom/100% no-repeat #13303c;
          }
          header#header_main {
            background: #13303c !important;
            box-shadow: 0 16px 32px 0 rgba(196, 203, 255, 0);
          }
          .wg-banner-1 .content .title h2,
          .wg-result .content .title h2 {
            color: #fff;
            font-size: 56px;
            font-weight: 700;
            line-height: 70px;
            letter-spacing: 0;
            margin-bottom: 24px;
            padding-left: 110px;
            padding-right: 110px;
          }
          .wg-banner-1 .content .title {
            color: #fff;
            font-size: 65px;
            font-weight: 700;
            line-height: 75px;
            letter-spacing: -1.95px;
            margin-bottom: 24px;
          }
          .arrow-hero {
            position: absolute;
            top: -25px;
            margin-left: 75px;
          }
          .rocket-gif,
          .spin-wheel-gif {
            width: 60px;
            margin-left: 16px;
          }
          .wg-banner-1 .content p,
          .wg-result .content p {
            font-size: 24px;
            margin-bottom: 39px;
            padding-left: 200px;
            padding-right: 200px;
          }
          .tf-button:hover {
            background: #ffbf00;
            color: #000;
          }
          .btn-glow {
            padding: 16px 34px;
            box-shadow: 0 0 0 0 #ffbf00;
            -webkit-animation: 1.4s ease-out infinite glow;
            animation: 1.4s ease-out infinite glow;
          }
          @-webkit-keyframes glow {
            0% {
              box-shadow: 0 0 0 0 #ffbf00;
            }
            50% {
              box-shadow: 0 0 30px 0 #ffbf00;
            }
          }
          @keyframes glow {
            0% {
              box-shadow: 0 0 0 0 #ffbf00;
            }
            50% {
              box-shadow: 0 0 30px 0 #ffbf00;
            }
          }
          .cust-padding,
          .cust-padding-2,
          .cust-padding-3 {
            padding: 100px 0;
          }
          .bg-black,
          .spin-wheel-modal {
            background-color: #13303c !important;
          }
          .heading-section .sub-title {
            color: #696969;
            font-size: 22px;
            font-weight: 500;
            margin-top: -9px;
          }
          .text-gradient {
            background: #52bdc7;
            -webkit-background-clip: text;
            font-size: 20px;
            font-weight: 700 !important;
            margin-top: 0 !important;
          }
          .head-gradient {
            font-size: 40px;
            background: #52bdc7;
            -webkit-background-clip: text;
            line-height: 45px;
            font-weight: 600;
          }
          .fw-bold,
          .gallery-sub-head,
          .section-head,
          b,
          cite,
          strong {
            font-weight: 700;
          }
          .head-gradient,
          .text-gradient {
            -webkit-text-fill-color: transparent;
            color: #696969;
          }
          .guide-1,
          .guide-2,
          .vison-1,
          .vison-2 {
            position: absolute;
          }
          .vison-1 {
            top: -193px;
            right: -139px;
          }
          .vison-2 {
            top: 109px;
            left: -55px;
          }
          .heading-section {
            margin-bottom: 40px;
            padding: 0 10px;
          }
          .free-con img {
            width: 70px;
          }
          @media (max-width: 767px) {
            .wg-banner-1 {
              padding: 50px 0 !important;
            }
          }
        `}
      </style>

      {/* Hero Section Start Here */}
      <div className="wg-banner-1 border-line-btm">
        <div className="themesflat-container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="content text-center position-relative">
                <div className="star-icon">
                  <LazyLoad height={50} offset={100}>
                    <img
                      src={star}
                      className="star-1"
                      alt="ib-img"
                      loading="lazy"
                    />
                  </LazyLoad>
                  <LazyLoad height={50} offset={100}>
                    <img
                      src={star}
                      className="star-2"
                      alt="ib-img"
                      loading="lazy"
                    />
                  </LazyLoad>
                </div>
                <div className="title hero-title">
                  <h2>
                    Unleash the Power of Your App with the Idea Booster
                    <span>
                      <img
                        src={rocket}
                        className="rocket-gif"
                        alt="ib-img"
                        loading="lazy"
                      />
                    </span>
                    Program
                  </h2>
                </div>
                <p className="hero-para">
                  We empower you to showcase the potential of your app idea to
                  the world by meticulously designing it and strategically
                  positioning its value.
                </p>

                <div className="position-relative">
                  <a
                    href="#"
                    className="tf-button get-start h45 mx-auto btn-glow"
                    data-bs-toggle="modal"
                    data-bs-target="#meeting"
                  >
                    <span>READY TO START?</span>
                  </a>
                  <LazyLoad height={50} offset={100}>
                    <img
                      src={arrow}
                      className="arrow-hero"
                      alt="ib-img"
                      loading="lazy"
                    />
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End Here */}
    </>
  );
};

export default Hero;
