import React from "react";


// cutout imports
import cutout5 from "../../../../app/assets/images/idea-booster/cutout5.png";
const Hero = () => {
  return (
    <>
      <style jsx>
        {`
          
        `}
      </style>
{/* Navigating Your App's Journey Section Start Here */}
<div class="wg-result cust-padding bg-black border-line-btm">
          <div class="themesflat-container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="sub-section">
                  <h5 class="gallery-sub-head mb-0">
                    Navigating Your App's Journey
                  </h5>
                  <h3 class="text-white mb-4">
                    Let's Begin Your Journey Together
                  </h3>
                  <p>
                    At CAP Digisoft, we're not just offering our expertise;
                    we're ready to invest our time to ensure your app idea
                    flourishes. We believe that the foundation of a successful
                    project starts with open communication, which is why talking
                    to us costs nothing.{" "}
                  </p>
                  <br />
                  <p>
                    Our team is a global assembly of{" "}
                    <b>
                      top talents from India, Poland, Central America, and the
                      USA, bringing a wealth of diverse skills and perspectives
                      to your project.
                    </b>{" "}
                    We challenge the notion that access to such high-caliber
                    professionals should be exclusive to Fortune 100 companies.
                    With CAP Digisoft, this level of expertise and dedication is
                    now available to you, ready to guide your app from concept
                    to market success.
                  </p>
                  <br />
                  <p>
                    We encourage you to schedule a no-obligation consultation
                    today and discover how our commitment can transform your
                    vision into reality.
                  </p>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="mx-auto text-center mt-5">
                  <img className="img-fluid" src={cutout5} alt="ib-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section End Here */}
    </>
  );
};

export default Hero;
