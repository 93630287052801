/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./idea-booster.css";
import HeaderDark from "../../../common/header/HeaderDark";
import FooterOne from "../../../common/footer/FooterOne";

import Hero from "./sub-components/hero";
import Inovative from "./sub-components/inovative";
import Unlocking from "./sub-components/unlocking";
import Guidance from "./sub-components/guidance";
import Victory from "./sub-components/victory";
import Journey from "./sub-components/journey";
import OurInovations from "./sub-components/ourInovations";
import Leverage from "./sub-components/leverage";
import AppJourney from "./sub-components/appJourney";
import TurnDream from "./sub-components/turnDream";
import Navigating from './sub-components/navigating';
import Testimonial from "./sub-components/testimonial";
import Apps from "./sub-components/apps";
import Confidently from "./sub-components/confidently";





const IdeaBooster = () => {
  
  return (
    <>
      <div className="idea-booster-page">
        <HeaderDark />
        <Hero />
        <Inovative />
        <Unlocking />
        <Guidance />
        <Victory />
        <Journey />
        <OurInovations />
        <Leverage />
        <AppJourney />
        <TurnDream />
        <Navigating/>
        <Testimonial/>
        <Apps/>
        <Confidently/>

        

       
        

        {/* Footer Section Start Here */}
        <FooterOne />
        {/* Section End Here */}
      </div>
      {/* Page End Here */}
    </>
  );
};

export default IdeaBooster;
